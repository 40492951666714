<template lang="pug">
  div.wrap-register-credit-card.f.fh
    div.container-register-credit-card.py14
      div.mb24
        //- span.block.text-center.bold.mb12(v-if="isRetry") {{retryTitle}}
        span.block.text-center.bold.mb8 カード登録
        span(v-if="planSettings.planId === 'TEAM'").text-center.block.fz14.mb12 チームプランの購入を開始します
      div.wrap-card-info.mb8
        //- span.block.mb20.fz12(v-if="isRetry") {{retryMessage}}
        div.custom-input-group
          span.label(for="card-number") カード番号
          div.input(id="card-number")
          //- hint="対応カード：Visa,Mastercard,American Express,Discover,Diners Club,JCB")
        div.custom-input-group
          span.label(for="card-holder-name") 名義
          input.input.card-holder-name(id="card-holder-name"
            v-model="cardHolderName"
            :placeholder="isCreditCardInputMounted ? 'Taro Yamada' : ' '")
        div.f
          div.expiration.mr12
            div.custom-input-group
              span.label(for="card-expiry") 有効期限
              div.input(id="card-expiry")
          div.cvv
            div.custom-input-group
              span.label(for="card-cvv") セキュリティーコード
              div.input(id="card-cvv")
      div.wrap-coupon-code.mb30
        span(v-if="!shouldInputCouponCode"
          @click="shouldInputCouponCode = !shouldInputCouponCode"
          ).toggle-coupon-form.cta-text.text-center.block.fz12.cursor-pointer クーポンコードをお持ちの方
        div.custom-input-group(v-else)
          span.label(for="coupon-code") クーポンコード
          input.input.card-holder-name(id="coupon-code"
            v-model="couponCode"
            :placeholder="'123456'"
            persistent-hint
            :hint="couponDescriptions[couponCode]"
            @input="handleChangeCouponCode")
          span.label {{couponDescriptions[couponCode]}}&nbsp;
      //- v-btn.next-button(@click="next()" width="100%"
        color="#2196F3" dark).mb20 確認
      div.mt24
        span.fz12.block.text-center.mb2 請求書によるお支払いをご希望の方は
        span.fz12.block.text-center.mb2 #[a(href="mailto:hello@meetawesome.jp?subject=[MeetAwesome]決済方法についての問合せ（カード支払いによる決済ができない場合）").here-link メール]にてお問い合わせください

    ItemButtomBtn(label="次へ" :active="isInputted" :loading="showNowLoading" @onBtn="next")
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-register-credit-card {
  height: calc(100vh - #{$header_height});
  .container-register-credit-card {
    width: $base_width_percent;
    max-width: $base_max_width_px;
    margin: 0 auto;

    .cta-text {
      color: $active_color;
    }

    .custom-input-group {
      margin-bottom: 20px;
      .label {
        color: gray !important;
        font-size: 12px;
      }
      .input {
        margin-bottom: 6px;
        height: 26px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.7);
      }
    }

    .card-holder-name {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      border: none;
      border-radius: 0;
      outline: none !important;
    }
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import { loadStripe } from '@stripe/stripe-js'
const planSettingsStore = createNamespacedHelpers('planSettings')

const STRIPE_PUBLISHABLE_KEY = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY

import ItemButtomBtn from '@/components/item/ItemButtomBtn'
import { getCoupon } from '@/services/functions'
import { debounce } from '@/components/utils/utils'
// import { sendTrackingEvent } from '../utils/eventTracker'

export default {
  components: {
    ItemButtomBtn
  },
  computed: {
    ...mapStateAuth(['uid']),
    ...planSettingsStore.mapState(['planSettings']),
    isInputted() {
      if (
        this.cardNumberError ||
        !this.cardHolderName ||
        this.cardCvvError ||
        this.cardExpirationDateError
      ) {
        return false
      }
      return true
    }
  },
  data() {
    return {
      /**
       * @type {Stripe}
       */
      stripe: null,
      isCreditCardInputMounted: false,
      cardNumberError: 'カード番号をご確認ください',
      cardHolderName: '',
      // cardExpirationDate: '',
      cardExpirationDateError: '有効期限をご確認ください',
      // cardCvv: '',
      cardCvvError: 'CVVをご確認ください',
      shouldInputCouponCode: false,
      couponCode: '',
      couponDescriptions: {},
      coupons: {},
      showNowLoading: false
    }
  },
  async created() {
    if (this.$route.params.planType === 'team-plan') {
      this.planSettings.planId = 'TEAM'
    }
    // auto redirect to select-plan page if planId is not set
    if (!this.planSettings.planId) {
      this.$router.replace('/select-plan')
      return
    }

    this.setupCardForm()
    this.cardHolderName = this.planSettings.cardHolderName
    this.couponCode = this.planSettings.couponCode
    this.shouldInputCouponCode = !!this.couponCode
  },
  methods: {
    ...planSettingsStore.mapMutations(['updatePlanSettings']),
    setupCardForm() {
      if (this.isCreditCardInputMounted) {
        return
      }

      loadStripe(STRIPE_PUBLISHABLE_KEY).then((stripe) => {
        this.stripe = stripe

        var style = {
          base: {
            fontFamily: 'Helvetica',
            fontSize: '16px'
          }
        }

        var elements = stripe.elements({
          locale: 'ja'
        })

        // card-number
        this.cardNumberElement = elements.create('cardNumber', {
          style,
          placeholder: '0000 0000 0000 0000'
        })
        this.cardNumberElement.on('ready', () => {
          this.isCreditCardInputMounted = true
        })
        this.cardNumberElement.on('change', (data) => {
          let errorMessage = null
          if (data.empty) {
            errorMessage = 'カード番号をご確認ください'
          } else if (data.error) {
            errorMessage = data.error.message
          }
          this.cardNumberError = errorMessage
        })
        this.cardNumberElement.mount('#card-number')

        // card-expiry
        this.cardExpiryElement = elements.create('cardExpiry', {
          style,
          placeholder: 'mm/yy'
        })
        this.cardExpiryElement.on('change', (data) => {
          let errorMessage = null
          if (data.empty) {
            errorMessage = '有効期限をご確認ください'
          } else if (data.error) {
            errorMessage = data.error.message
          }
          this.cardExpirationDateError = errorMessage
        })
        this.cardExpiryElement.mount('#card-expiry')

        // card-cvc
        this.cardCvvElement = elements.create('cardCvc', {
          style,
          placeholder: '0000'
        })
        this.cardCvvElement.on('change', (data) => {
          let errorMessage = null
          if (data.empty) {
            errorMessage = 'CVVをご確認ください'
          } else if (data.error) {
            errorMessage = data.error.message
          }
          this.cardCvvError = errorMessage
        })
        this.cardCvvElement.mount('#card-cvv')
      })
    },
    handleChangeCouponCode: debounce(async function () {
      const couponCode = this.couponCode
      if (couponCode && !this.couponDescriptions[couponCode]) {
        const result = (await getCoupon(this.couponCode).catch(() => {})) || { data: {} }
        const coupon = result.data.coupon

        if (coupon) {
          this.couponDescriptions = {
            ...this.couponDescriptions,
            [couponCode]: coupon['name']
          }
          this.coupons = {
            ...this.coupons,
            [couponCode]: coupon
          }
        }
      }
    }),
    async next() {
      if (this.cardNumberError) {
        alert(this.cardNumberError)
        return
      }

      if (!this.cardHolderName) {
        alert('名義をご入力ください')
        return
      }

      if (this.cardExpirationDateError) {
        alert(this.cardExpirationDateError)
        return
      }

      if (this.cardCvvError) {
        alert(this.cardCvvError)
        return
      }

      if (this.shouldInputCouponCode && this.couponCode) {
        if (!this.couponDescriptions[this.couponCode]) {
          this.showNowLoading = true
          const result = (await getCoupon(this.couponCode).catch(() => {})) || { data: {} }
          const coupon = result.data.coupon
          if (!coupon) {
            this.isValid = false
            this.showNowLoading = false
            alert('クーポンコードは正しくありません')
            // sendTrackingEvent(`${this.$route.path}:next`, {
            //   code: 'coupon code is invalid'
            // }, this.uid)
            return
          }
        }
      }

      this.isValid = true
      this.showNowLoading = true

      // const result = await this.stripe.createToken(this.cardNumberElement, {
      //   name: this.cardHolderName,
      // })
      const result = await this.stripe
        .createPaymentMethod({
          card: this.cardNumberElement,
          type: 'card'
        })
        .catch((error) => {
          this.isValid = false
          if (error['message']) {
            alert(error['message'])
          } else {
            alert('カードの情報をご確認ください')
          }
          // sendTrackingEvent(`${this.$route.path}:next`, {
          //   code: 'can\'t create payment method',
          //   error
          // }, this.uid)
        })

      if (result && result.paymentMethod) {
        const stripePaymentMethodId = result.paymentMethod.id
        this.updatePlanSettings({
          stripePaymentMethodId,
          cardHolderName: this.cardHolderName,
          couponCode: this.couponCode
        })
        this.$router.push('/confirm-subscription')
        this.showNowLoading = false
        // sendTrackingEvent(`${this.$route.path}:next`, {
        //   code: 'go to /confirm-subscription'
        // }, this.uid)
        return
      } else if (result && result.error && this.isValid) {
        this.isValid = false
        alert(result.error.message || 'カードの情報をご確認ください')
      }

      if (!this.isValid) {
        this.showNowLoading = false
        // sendTrackingEvent(`${this.$route.path}:next`, {
        //   result,
        //   code: 'invalid'
        // }, this.uid)
        return
      }

      this.showNowLoading = false
    }
  }
}
</script>
