<template lang="pug">
  div.wrap-item-buttom-btn.f.fh
    div(@click="onBtn"
      :class="{'active': active && !loading}"
      :style="`background: ${btnColor};`").btn-next.f.fh.py12
      v-progress-circular(v-if="loading" indeterminate color="white" :width="3" :size="26")
      span(v-else) {{label}}
</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-item-buttom-btn {
  position: fixed;
  z-index: 1;
  width: 100%;
  left: 0; bottom: 12px;
  .btn-next {
    width: $base_width_percent;
    max-width: $base_max_width_px;
    border-radius: 28px;
    filter: $drop_shadow;
    span {
      color: $dark_primary_text_color;
    }
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  props: {
    active: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
    },
    label: {
      type: String,
      required: true
    },
    activeColor: {
      type: String,
      default: '#4285f4'
    }
  },
  computed: {
    ...mapStateAuth(['uid']),
    btnColor () {
      return this.active && !this.loading ? this.activeColor : '#999'
    }
  },
  methods: {
    onBtn () {
      if (!this.active || this.loading) return
      this.$emit('onBtn')
    }
  }
}
</script>
